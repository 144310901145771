import React from 'react';
import Header from './components/Header/Header'; // Asegúrate de que la ruta sea correcta
import Countdown from './components/Countdown/Countdown'; // Asegúrate de que la ruta sea correcta
import EventDetail from './components/EventDetail/EventDetail';
import CelebrationDetails from './components/CelebrationDetails/CelebrationDetails';
import RSVPButton from './components/RSVPButton/RSVPButton';
import AboutCommunion from './components/AboutCommunion/AboutCommunion';
import GiftSection from './components/GiftSection/GiftSection';
import './App.css'; // Asume que tienes estilos globales aquí

const App = () => {
  return (
    <div className="App" >
      <div className='fixed-background' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/fondo-comunion.png)` }}>

      </div>
      <Header />
      <AboutCommunion />
      <EventDetail
        title="Ceremonia"
        description="Te invitamos a celebrar la Primera Comunión de Rocío en la Parroquia Nuestra Señora de Loreto."
        hora='12:00'
        buttonText="Cómo llegar"
        buttonLink="https://www.google.com/maps/place/Parroquia+Castrense+de+Nuestra+Se%C3%B1ora+de+Loreto/@37.8129849,-0.8028148,17z/data=!3m1!4b1!4m6!3m5!1s0xd630e2ee1ca97b9:0xb4f81856e48ae4b3!8m2!3d37.8129808!4d-0.7979439!16s%2Fg%2F11cm9x69qr?entry=ttu"
      />
      <EventDetail
        title="Celebración"
        description="Después de la ceremonia, continuaremos celebrando en Aquario Celebraciones."
        hora='13:30'
        buttonText="Ubicación de la celebración"
        buttonLink="https://www.google.com/maps/place/Aquario/@37.7355655,-0.8982102,17z/data=!3m1!4b1!4m6!3m5!1s0xd63134589692d91:0xda8ff1800b4d7905!8m2!3d37.7355613!4d-0.8956353!16s%2Fg%2F12qgh4qv9?entry=ttu"
      />
      <RSVPButton />
      <GiftSection />
    </div>
  );
};

export default App;
