import React from 'react';
import './AboutCommunion.css'; // Asegúrate de crear este archivo para los estilos

const AboutCommunion = () => {
  return (
    <section className="about-communion">
      <h2>Mi Primera Comunión</h2>
      <p>Llega el día de mi Primera Comunión, me he preparado muy bien para recibir a Jesús y me encantaría que vinieras a celebrarlo conmigo!</p>
      <p>Que este momento especial sea para mi fuente de luz en el camino de mi vida.</p>
    </section>
  );
};

export default AboutCommunion;
