import React, { useState } from 'react';
import './GiftSection.css'; // Asegúrate de crear este archivo CSS para estilizar tu componente

function GiftSection() {
  const [showDetails, setShowDetails] = useState(false);

  const handleClick = () => {
    setShowDetails(true);
  };

  return (
    <div className="gift-section">
      <h2>Regalos</h2>
      {!showDetails ? (
        <button onClick={handleClick}>¿Qué te regalo?</button>
      ) : (
        <div className="gift-message">
          <p>El regalo es opcional… <br/> ¡la asistencia obligatoria!</p>
          <p>Cualquier regalo será bienvenido y si lo preferís podéis colaborar a un viaje con experiencias muy divertidas… <br/>¡¡¡Disney World me espera!!!<br/></p>
          <p style={{ marginTop: '30px' }} >ES76 3058 0239 4427 2400 2315</p>
        </div>
      )}
    </div>
  );
}

export default GiftSection;
