// Header.js o como se llame tu componente
import React, { useState, useEffect } from 'react';
import './Header.css'; // Asegúrate de actualizar este archivo con los nuevos estilos
import Countdown from '../Countdown/Countdown'; // Asegúrate de que la ruta de importación sea correcta

const Header = () => {
  const [backgroundImage, setBackgroundImage] = useState(0);
  const images = [
    'images/PHOTO-2024-02-05-18-31-25.jpg',
    'images/PHOTO-2024-02-05-18-34-09.jpg',
    'images/PHOTO-2024-02-05-21-25-04.jpg',
    'images/PHOTO-2024-02-05-22-02-16.jpg',
    'images/PHOTO-2024-02-05-22-02-29.jpg',
    'images/PHOTO-2024-02-05-22-02-41.jpg',
    'images/PHOTO-2024-02-05-22-02-53.jpg',
    'images/PHOTO-2024-02-05-22-03-03.jpg',
    'images/PHOTO-2024-02-05-22-07-58.jpg',
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBackgroundImage((current) => (current + 1) % images.length);
    }, 3000); // Cambia la imagen cada 5 segundos

    return () => clearInterval(intervalId); // Limpieza al desmontar el componente
  }, [images.length]);

  return (
    
    <div>
      <div className="header" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/${images[backgroundImage]})` }}>
      
      </div>

      <div className="overlay">
        <h1 className='pacifico-regular'>Rocío</h1>
        <Countdown targetDate="2024-05-04" />
      </div>

    </div>

    
  );
};

export default Header;
