import React from 'react';
import './RSVPButton.css'; // No olvides crear y enlazar este archivo CSS para estilizar el botón

const RSVPButton = () => {
  const handleRSVP = () => {
    
  
    window.open('https://forms.gle/a2RLSQhd55B8dhMA6', '_blank');

  };

  return (
    <div className="rsvp-button-container">
      <button onClick={handleRSVP}>Confirmar Asistencia</button>
    </div>
  );
};

export default RSVPButton;
