import React from 'react';
import './EventDetail.css'; // Asegúrate de que este archivo contiene los estilos necesarios

const EventDetail = ({ title, description, hora, buttonText, buttonLink }) => {
  return (
    <div className="event-detail">
      <h2>{title}</h2>
      <p>{hora}</p>
      <p>{description}</p>
      
      {buttonText && buttonLink && (
        <a href={buttonLink} target="_blank" rel="noopener noreferrer">
          <button>{buttonText}</button>
        </a>
      )}
    </div>
  );
};

export default EventDetail;
